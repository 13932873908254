import Image from 'next/image';
import BillingToggle from './ui/billing-toggle';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import {
  ArrowRight,
  FileJson2,
  Folder,
  Headset,
  Lightbulb,
  Link,
  MessageCircleMore,
  Pencil,
  ShieldCheck,
  Sparkle,
} from 'lucide-react';
import { useState } from 'react';
import { MdOutlineSupportAgent } from 'react-icons/md';
import PricingCard from './PricingCard';
import { Button } from './ui/button';

export interface PricingTier {
  name: string;
  price: Record<string, number | string>;
  description: string;
  features: string[];
  cta: string;
  highlighted?: boolean;
  popular?: boolean;
}

function PricingSection({
  handleUpgrade,
  source,
}: {
  handleUpgrade: (billingPeriod: 'monthly' | 'annual') => void;
  source?:
    | 'project_creation'
    | 'edits_limit'
    | 'low_edits'
    | 'subscription_expired'
    | 'premium_feature'
    | null;
}) {
  const [selectedBilling, setSelectedBilling] = useState<'monthly' | 'annual'>(
    'monthly',
  );

  const handleOnPremium = () => {
    handleUpgrade(selectedBilling);
  };

  const getPremiumCTA = () => {
    switch (source) {
      case 'project_creation':
        return 'Upgrade for Unlimited Projects';
      case 'edits_limit':
      case 'low_edits':
        return 'Upgrade for Unlimited Edits';
      case 'subscription_expired':
        return 'Renew Subscription';
      case 'premium_feature':
        return 'Upgrade to Access';
      default:
        return 'Upgrade to Premium';
    }
  };

  return (
    <>
      <BillingToggle
        selectedBilling={selectedBilling}
        setSelectedBilling={setSelectedBilling}
      />

      <div className="mt-5 grid grid-cols-1 gap-6 px-2 sm:px-0 md:grid-cols-3">
        {/* Free Plan */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="mx-auto w-full max-w-sm md:mx-0"
        >
          <PricingCard
            tier={{
              name: 'Free Forever',
              price: { monthly: 0 },
              description: 'Power Features',
              features: [
                '5 Projects',
                '100 Edits Per Project',
                'Supabase & ChatGPT Integrations',
                'Chat Support',
              ],
              cta: 'Current Plan',
              highlighted: false,
            }}
            paymentFrequency="monthly"
            customButton={
              <Button variant="outline" className="w-full">
                Current Plan
              </Button>
            }
            iconMap={{
              '5 Projects': <Folder size={14} />,
              '100 Edits Per Project': <Pencil size={14} />,
              'Supabase & ChatGPT Integrations': <Link size={14} />,
              'Chat Support': <MessageCircleMore size={14} />,
            }}
          />
        </motion.div>

        {/* Premium Plan */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mx-auto w-full max-w-sm md:mx-0"
        >
          <PricingCard
            tier={{
              name: 'Premium',
              price: {
                monthly: selectedBilling === 'monthly' ? 89 : 59,
              },
              description: 'Everything in Free plus',
              features: [
                'Unlimited Projects',
                'Unlimited Edits',
                'Unlimited Private Projects',
                'Priority Support',
              ],
              cta: getPremiumCTA(),
              popular: true,
            }}
            paymentFrequency="monthly"
            customButton={
              <Button onClick={handleOnPremium} className="w-full">
                {getPremiumCTA()}
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            }
            iconMap={{
              'Unlimited Projects': <Folder className="" size={14} />,
              'Unlimited Edits': <Pencil className="" size={14} />,
              'Unlimited Private Projects': (
                <ShieldCheck className="" size={14} />
              ),
              'Priority Support': <Headset className="" size={14} />,
            }}
          />
        </motion.div>

        {/* Enterprise Plan */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="mx-auto w-full max-w-sm md:mx-0"
        >
          <PricingCard
            tier={{
              name: 'Enterprise',
              price: { monthly: 'Custom' },
              description: 'Everything in Premium plus',
              features: [
                'Deep Thinking & Website Cloner',
                'Multiple LLM Models Supported',
                'Custom Integration & Feature Request',
                'Dedicated Account & Tech Support',
              ],
              cta: 'Book Call with Founder',
              highlighted: true,
            }}
            paymentFrequency="monthly"
            customButton={
              <Button
                variant="outline"
                className="w-full text-black"
                onClick={() => {
                  window.open(
                    'https://cal.com/kartik-sharma-7ikcki/shape-the-future-of-creatr',
                    '_blank',
                  );
                }}
              >
                Book Call with Founder
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            }
            iconMap={{
              'Deep Thinking & Website Cloner': <Lightbulb size={14} />,
              'Multiple LLM Models Supported': <Sparkle size={14} />,
              'Custom Integration & Feature Request': <FileJson2 size={14} />,
              'Dedicated Account & Tech Support': (
                <MdOutlineSupportAgent size={14} />
              ),
            }}
          />
        </motion.div>
      </div>
    </>
  );
}

export default PricingSection;
