import { useEffect, useState } from 'react';
import { useEditsInfo } from '@/hooks/useEditsInfo';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { InfoIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { usePricingStore } from '@/store/usePricingStore';

export function EditsCounter() {
  const { editsInfo, fetchEditsInfo } = useEditsInfo();
  const [isLoading, setIsLoading] = useState(true);
  const showPricing = usePricingStore(state => state.showPricing);

  useEffect(() => {
    const initializeEditsInfo = async () => {
      try {
        await fetchEditsInfo();
      } finally {
        setIsLoading(false);
      }
    };
    initializeEditsInfo();
  }, []); // Empty dependency array - only run on mount

  // Don't show counter if loading, no data, or user is premium
  if (isLoading || !editsInfo || editsInfo.isPremium) return null;

  // Ensure we have valid numbers for remaining and total
  const remaining =
    typeof editsInfo.remaining === 'number' ? editsInfo.remaining : 0;
  const total = typeof editsInfo.total === 'number' ? editsInfo.total : 100;

  // Calculate font weight based on remaining edits
  const getFontWeight = () => {
    const percentage = remaining / total;

    // Map percentage to font weight (400-700)
    // Lower percentage = higher font weight
    if (percentage <= 0.25) return 'font-bold'; // 700
    if (percentage <= 0.5) return 'font-semibold'; // 600
    if (percentage <= 0.75) return 'font-medium'; // 500
    return 'font-normal'; // 400
  };

  // Get text color class based on remaining edits
  const getTextColorClass = () => {
    const percentage = remaining / total;
    return percentage <= 0.2 ? 'text-zinc-700' : 'text-zinc-500';
  };

  // Get display text - show special message when less than 20 edits remaining
  const getDisplayText = () => {
    if (remaining < 20) {
      return `${remaining} edits remaining`;
    }
    return `${remaining}/${total} edits`;
  };

  // Add a subtle emphasis class for low edits
  const getLowEditsEmphasis = () => {
    return remaining < 20 ? 'tracking-tight' : '';
  };

  // Check if we should show the upgrade button (less than 20 edits)
  const showUpgradeButton = remaining <= 80;

  // Handler for upgrade button click
  const handleUpgradeClick = () => {
    const isOutOfEdits = remaining === 0;
    showPricing(isOutOfEdits ? 'edits_limit' : 'low_edits', {
      limits: {
        remaining,
        total,
      },
      upgrade: {
        monthly: {
          amount: 89,
          interval: 'month',
        },
        annual: {
          amount: 59,
          interval: 'year',
          savings: '33%',
        },
      },
    });
  };

  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <div className="flex items-center">
          <Tooltip delayDuration={300}>
            <TooltipTrigger asChild>
              <div
                className={`flex cursor-help items-center text-xs ${getTextColorClass()} ${getFontWeight()} ${getLowEditsEmphasis()}`}
              >
                <span>{getDisplayText()}</span>
                <InfoIcon className="ml-1 h-3 w-3 opacity-70" />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top" align="end" className="max-w-xs">
              <p className="text-xs">
                {remaining > 0
                  ? `Free plan includes ${total} edits per project. Upgrade
                for unlimited edits and additional features.`
                  : 'Looks like you have run out of edits. Upgrade plan to continue on this project.'}
              </p>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>

      {/* Render upgrade button when edits are low */}
      {showUpgradeButton && (
        <Button
          variant="outline"
          size="sm"
          className="h-6 rounded-lg border-zinc-200 !bg-[#F8FAFB] px-2 py-0 text-xs text-zinc-700 hover:bg-zinc-200"
          onClick={handleUpgradeClick}
        >
          Upgrade
        </Button>
      )}
    </div>
  );
}
