import { cn } from '@/lib/utils';
import { BadgeCheck } from 'lucide-react';
import { Badge } from './ui/badge';
import { PricingTier } from './PricingSection';
import { Card } from './ui/card';

interface PricingCardProps {
  tier: PricingTier;
  paymentFrequency: string;
  customButton?: React.ReactNode;
  iconMap?: Record<string, React.ReactNode>;
}

// Modified PricingCard component based on the 21st dev styling
function PricingCard({
  tier,
  paymentFrequency,
  customButton,
  iconMap = {},
}: PricingCardProps) {
  const price = tier.price[paymentFrequency];
  const isHighlighted = tier.highlighted;
  const isPopular = tier.popular;

  return (
    <Card
      className={cn(
        'relative flex h-full flex-col gap-4 overflow-hidden p-4 sm:gap-6 sm:p-6',
        isHighlighted
          ? 'bg-foreground text-background'
          : 'bg-background text-foreground',
        isPopular && 'ring-2 ring-primary',
      )}
    >
      {isHighlighted && <HighlightedBackground />}
      {isPopular && <PopularBackground />}

      <h2 className="flex items-center gap-2 text-lg font-medium sm:gap-3 sm:text-xl">
        {tier.name}
        {isPopular && (
          <Badge variant="secondary" className="z-10 mt-1 text-xs sm:text-sm">
            🔥 Most Popular
          </Badge>
        )}
      </h2>

      <div className="relative">
        {typeof price === 'number' ? (
          <div className="space-y-1">
            <div className="font text-xs text-neutral-400 sm:text-sm">
              <div
                className={cn(
                  'mr-1 text-3xl font-normal sm:text-4xl',
                  isHighlighted ? 'text-background' : 'text-foreground',
                )}
              >
                ${price}
              </div>
              per month
            </div>
          </div>
        ) : (
          <div className="font text-xs text-neutral-400 sm:text-sm">
            <div
              className={cn(
                'mr-1 text-3xl font-normal sm:text-4xl',
                isHighlighted ? 'text-background' : 'text-foreground',
              )}
            >
              {price}
            </div>
            &nbsp;
          </div>
        )}
      </div>

      <div className="flex-1 space-y-3 sm:space-y-4">
        <div
          className={cn(
            'text-xs sm:text-sm',
            isHighlighted ? 'text-background/80' : 'text-[#838383]',
          )}
        >
          {tier.description}
        </div>

        <div className="space-y-3 text-xs sm:space-y-4 sm:text-sm">
          {tier.features.map((feature: string, index: number) => (
            <div
              key={index}
              className={cn(
                'flex items-center gap-2 font-[350]',
                isHighlighted ? 'text-background' : 'text-black',
              )}
            >
              {iconMap[feature] || <BadgeCheck className="h-3 w-3 sm:h-4 sm:w-4" />}
              {feature}
            </div>
          ))}
        </div>
      </div>

      {customButton}
    </Card>
  );
}

const HighlightedBackground: React.FC = () => (
  <div className="pointer-events-none absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:45px_45px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]" />
);

const PopularBackground: React.FC = () => (
  <div className="pointer-events-none absolute inset-0 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.1),rgba(255,255,255,0))]" />
);

export default PricingCard;
