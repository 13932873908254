'use client';

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import { Dialog, DialogContent } from '@/components/ui/Dialog';
import { useAuth } from '@clerk/nextjs';
import { api } from '@/lib/axios';
import { toast } from 'sonner';
import { Alert, AlertDescription, AlertTitle } from './alert';
import { usePricingStore } from '@/store/usePricingStore';
import PricingSection from '@/components/PricingSection';

type PricingSource =
  | 'project_creation'
  | 'edits_limit'
  | 'low_edits'
  | 'subscription_expired'
  | 'premium_feature'
  | null;

interface SubscriptionLimits {
  limits: {
    remaining: number;
    total: number;
  };
  upgrade: {
    monthly: {
      amount: number;
      interval: string;
    };
    annual: {
      amount: number;
      interval: string;
      savings: string;
    };
  };
}

const getLimitMessage = (
  source: PricingSource,
  limits?: { remaining: number; total: number },
) => {
  if (!source) return null;

  switch (source) {
    case 'subscription_expired':
      return {
        title: 'Subscription Expired',
        description:
          'Your premium subscription has expired. Renew now to continue using all premium features and avoid any interruption in service.',
        variant: 'destructive' as const,
      };
    case 'project_creation':
      return {
        title: 'Project Limit Reached',
        description: `You've reached the limit of ${limits?.total || 5} projects on the free plan. Upgrade to Premium for unlimited projects.`,
        variant: 'default' as const,
      };
    case 'edits_limit':
      return {
        title: 'Edit Limit Reached',
        description:
          "You've reached the limit of 100 edits for this project. Upgrade to Premium for unlimited edits.",
        variant: 'default' as const,
      };
    case 'low_edits':
      return {
        title: `Running Low on Edits`,
        description: `You have ${limits?.remaining} edits remaining out of ${limits?.total}. Upgrade to Premium for unlimited edits.`,
        variant: 'default' as const,
      };
    default:
      return null;
  }
};

//

export const PricingPopup: React.FC = () => {
  const {
    isOpen,
    source,
    limits,
    setOpen,
    isLoading,
    lastUpgradeAttempt,
    setLoading,
    setUpgradeStatus,
  } = usePricingStore();
  const { getToken } = useAuth();

  const limitMessage = getLimitMessage(source, limits?.limits);

  // Handle component unmount
  useEffect(() => {
    return () => {
      setLoading(false);
      setUpgradeStatus('idle');
    };
  }, []);

  // Handle successful upgrade
  useEffect(() => {
    if (
      lastUpgradeAttempt.status === 'success' &&
      lastUpgradeAttempt.redirectUrl
    ) {
      window.location.href = lastUpgradeAttempt.redirectUrl;
    }
  }, [lastUpgradeAttempt]);

  const handleUpgrade = async (billingPeriod: 'monthly' | 'annual') => {
    try {
      setLoading(true);
      setUpgradeStatus('loading');

      const token = await getToken();
      if (!token) {
        toast.error('Please sign in to upgrade your subscription');
        setUpgradeStatus('error', 'Authentication required');
        return;
      }

      const response = await api.post(
        '/api/stripe/create-checkout-session',
        { billingPeriod },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (response.data?.url) {
        setUpgradeStatus('success', undefined, response.data.url);
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || 'Failed to process upgrade request';
      toast.error(errorMessage);
      setUpgradeStatus('error', errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent 
        className="max-w-[95vw] max-h-[90vh] border-0 bg-white p-0 shadow-2xl sm:max-w-5xl sm:rounded-3xl"
        style={{ overflowY: 'auto' }}
      >
        <div className="relative p-3 pt-6 sm:p-4 sm:pt-8 md:p-8 md:pt-12">
          {/* Show error message if upgrade failed */}
          {lastUpgradeAttempt.status === 'error' && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-4 sm:mb-6"
            >
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  {lastUpgradeAttempt.error ||
                    'Something went wrong. Please try again.'}
                </AlertDescription>
              </Alert>
            </motion.div>
          )}

          {/* Show limit message */}
          {limitMessage && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-4 sm:mb-6"
            >
              <Alert
                variant={limitMessage.variant}
                className={
                  limitMessage.variant === 'destructive'
                    ? ''
                    : 'border-orange-200 bg-orange-50'
                }
              >
                <AlertCircle
                  className={`h-4 w-4 ${limitMessage.variant === 'destructive' ? '' : 'text-orange-600'}`}
                />
                <AlertTitle
                  className={
                    limitMessage.variant === 'destructive'
                      ? ''
                      : 'text-orange-600'
                  }
                >
                  {limitMessage.title}
                </AlertTitle>
                <AlertDescription
                  className={
                    limitMessage.variant === 'destructive'
                      ? ''
                      : 'text-orange-600'
                  }
                >
                  {limitMessage.description}
                </AlertDescription>
              </Alert>
            </motion.div>
          )}
          <div className="text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="mb-2 text-2xl font-semibold sm:mb-3 sm:text-3xl">
                {source === 'subscription_expired'
                  ? 'Renew Your Subscription'
                  : 'Choose Your Plan'}
              </h2>
              <p className="mx-auto mb-4 max-w-xl text-base text-gray-600 sm:mb-8 sm:text-lg">
                {source === 'subscription_expired'
                  ? 'Renew your subscription to continue enjoying all premium features'
                  : 'Select the perfect plan for your needs and start building amazing web apps today'}
              </p>
            </motion.div>
          </div>

          {/* Pricing Section */}
          <PricingSection handleUpgrade={handleUpgrade} source={source} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
