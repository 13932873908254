'use client';

import type { ChatRequestOptions, CreateMessage, Message } from 'ai';
import type React from 'react';
import {
  useRef,
  useEffect,
  useState,
  useCallback,
  type Dispatch,
  type SetStateAction,
  type ChangeEvent,
  memo,
  forwardRef,
} from 'react';
import { toast } from 'sonner';
import { useLocalStorage, useWindowSize } from 'usehooks-ts';
import { useDropzone } from 'react-dropzone';
import { debounce } from 'lodash';
import { useAuth } from '@clerk/nextjs';

import { cn } from '@/lib/utils';

import { ArrowUpIcon, PaperclipIcon, StopIcon, Wand2 } from './icons';
import { PreviewAttachment } from './preview-attachment';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import equal from 'fast-deep-equal';
import api from '@/lib/axios';
import { BookOpen, Loader2, Stars, Zap } from 'lucide-react';
import { Paperclip, Sparkles, X } from 'lucide-react';
import { useEnhancePrompt } from '@/hooks/useEnhancePrompt';
import { v4 } from 'uuid';
import { ProjectCreationSteps, useWebappStore } from '@/store/useWebappData';
import posthog from 'posthog-js';
import { POSTHOG_EVENTS } from '@/mixpanel/events';
import { useKnowledgeBaseStore } from '@/store/useKnowledgeBase';
import { useAttachmentsStore } from '@/store/useAttachmentsStore';

import IntegrationsButton from './IntegrationButton';
import { ScreensModal } from '../ScreensModal';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import SupabasePendingMigrations from './SupabasePendingMigrations';
import { ChevronDown, ChevronUp } from 'lucide-react';
import {
  CodeFile,
  CodeViewer,
  getLanguageFromPath,
} from '../ws/RightPanel/CodeViewer';
import { CodeBlock } from '../ui/code-block';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { ImagePreviewModal } from '../ui/image-preview-modal';
import ResendButton from './ResendButton';
import ResendIntegrationPopup from './ResendPopup/ResendIntegrationPopup';
import { EditsCounter } from './EditsCounter';
import { useEditsInfo } from '@/hooks/useEditsInfo';
import { KnowledgeBase } from '../ws/Header/KnowledgeBase';

export const INTEGRATIONS_LIST = [
  {
    id: '1',
    name: 'OpenAI',
    description: 'AI Language Models',
    icon: 'https://cdn.worldvectorlogo.com/logos/openai-2.svg',
    className: 'bg-white',
  },
];

interface FileUploadResponse {
  url: string;
  pathname: string;
  contentType: string;
}

interface Props {
  chatId: string | null;
  input: string;
  setInput: (value: string) => void;
  stop: () => void;
  messages: Array<Message>;
  setMessages: Dispatch<SetStateAction<Array<Message>>>;
  append: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  handleSubmit: (
    event?: {
      preventDefault?: () => void;
    },
    chatRequestOptions?: ChatRequestOptions,
  ) => void;
  className?: string;
  onDragActiveChange?: (isDragActive: boolean) => void;
  isOwner: boolean;
  files: CodeFile[];
  mode: 'build' | 'chat';
  setMode: (mode: 'build' | 'chat') => void;
}

const PureMultimodalInput = forwardRef<HTMLDivElement, Props>(
  function PureMultimodalInput(
    {
      chatId,
      input,
      setInput,
      stop,
      messages,
      setMessages,
      append,
      handleSubmit,
      className,
      onDragActiveChange,
      isOwner,
      mode,
      setMode,
    },
    ref,
  ) {
    const {
      data,
      isLoading: isApiLoading,
      isInspecting,
      inspectedElements,
      selectedVersion,
      removeInspectedElement,
      clearInspectedElements,
      setIsInspecting,
      getMigrations,
      isDraggingFile,
      setIsDraggingFile,
      iframeRef,
      activeIntegrations,
      setActiveIntegrations,
    } = useWebappStore();
    const {
      knowledge: knowledgeBase,
      isOpen: isKnowledgeBaseModelOpen,
      setIsOpen: setIsKnowledgeBaseModelOpen,
    } = useKnowledgeBaseStore();
    const {
      attachments,
      uploadQueue,
      addAttachments,
      removeAttachment,
      clearAttachments,
      setUploadQueue: storeSetUploadQueue,
      clearUploadQueue,
    } = useAttachmentsStore();

    const supabaseMigrations = getMigrations();

    const [addIntegrations, setAddIntegrations] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const projectStatus = data?.phase || null;
    const isLoading = isApiLoading
      ? false
      : projectStatus !== ProjectCreationSteps.COMPLETE &&
        projectStatus !== ProjectCreationSteps.FAILED;

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { width } = useWindowSize();

    const integrationsFF = true;
    const inspirationsFF = useFeatureFlagEnabled('inspirations');
    const chatFF = useFeatureFlagEnabled('chat-mode');

    const isComponent = data?.platform === 'component';

    const [isSourceExpanded, setIsSourceExpanded] = useState(false);

    const [files, setFiles] = useState<CodeFile[]>([]);

    const showErrorToast = useCallback(
      debounce(
        () => {
          toast.error('Only JPEG, PNG and WebP images are allowed');
        },
        1000,
        { leading: true, trailing: false },
      ),
      [],
    );

    const { getToken } = useAuth();

    const { editsInfo, fetchEditsInfo } = useEditsInfo();

    useEffect(() => {
      if (textareaRef.current) {
        adjustHeight();
      }
    }, []);

    const adjustHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
      }
    };

    const [localStorageInput, setLocalStorageInput] = useLocalStorage(
      'input',
      '',
    );

    useEffect(() => {
      if (textareaRef.current) {
        const domValue = textareaRef.current.value;
        // Prefer DOM value over localStorage to handle hydration
        const finalValue = domValue || localStorageInput || '';
        setInput(finalValue);
        adjustHeight();
      }
      // Only run once after hydration
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setLocalStorageInput(input);
    }, [input, setLocalStorageInput]);

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInput(event.target.value);
      adjustHeight();
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const { enhanceState, handleEnhancePrompt } = useEnhancePrompt({
      textareaRef,
      setPrompt: setInput,
    });

    const handlePaste = async (
      e: React.ClipboardEvent<HTMLTextAreaElement>,
    ) => {
      const items = e.clipboardData?.items;
      if (!items) return;

      // Find image items in clipboard
      const imageItems = Array.from(items).filter(
        item => item.type.indexOf('image') !== -1,
      );

      if (imageItems.length === 0) return;

      // Prevent default paste for images
      e.preventDefault();

      // Add items to upload queue
      const fileNames = imageItems.map(
        (_, index) => `pasted-image-${index + 1}.png`,
      );
      storeSetUploadQueue(fileNames);

      try {
        const userToken = await getToken();
        const formData = new FormData();

        imageItems.forEach((item, index) => {
          const file = item.getAsFile();
          if (!file) return;

          // Create a new file with a proper name
          const newFile = new File([file], `pasted-image-${index + 1}.png`, {
            type: file.type,
          });
          formData.append('files', newFile);
        });

        // Upload the files
        const response = await api.post('/api/web/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.data.success) {
          const uploadedFiles = Array.isArray(response.data.data)
            ? response.data.data
            : [response.data.data];

          const newAttachments = uploadedFiles.map(
            (fileData: FileUploadResponse) => ({
              url: fileData.url,
              name: fileData.pathname,
              contentType: fileData.contentType,
              id: v4(),
            }),
          );

          addAttachments(newAttachments);

          // Track the paste event
          posthog.capture(POSTHOG_EVENTS.CHAT_PASTE_IMAGE, {
            webId: chatId,
            fileCount: newAttachments.length,
          });
        }
      } catch (error: any) {
        console.error('Error uploading pasted files!', error);
        toast.error(
          error.response?.data?.message || 'Failed to upload pasted files',
        );
      } finally {
        clearUploadQueue();
      }
    };

    const handleFileChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        storeSetUploadQueue(files.map(file => file.name));

        try {
          const userToken = await getToken();
          const formData = new FormData();
          files.forEach(file => {
            formData.append('files', file);
          });

          const response = await api.post('/api/web/files/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          });

          if (response.data.success) {
            const uploadedFiles = Array.isArray(response.data.data)
              ? response.data.data
              : [response.data.data];

            const newAttachments = uploadedFiles.map(
              (fileData: FileUploadResponse) => ({
                url: fileData.url,
                name: fileData.pathname,
                contentType: fileData.contentType,
                id: v4(),
              }),
            );

            addAttachments(newAttachments);
          }
        } catch (error: any) {
          console.error('Error uploading files!', error);
          toast.error(
            error.response?.data?.message ||
              error.response?.data?.error?.message ||
              'Failed to upload files',
          );
        } finally {
          clearUploadQueue();
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      },
      [addAttachments, storeSetUploadQueue, clearUploadQueue],
    );

    const submitForm = useCallback(() => {
      handleSubmit(undefined, {
        experimental_attachments: attachments,
        body: {
          webId: chatId,
          integrations: activeIntegrations.map(integration => ({
            id: integration,
            ...INTEGRATIONS_LIST.find(i => i.id === integration),
          })),
          selectedElements: inspectedElements,
          mode: mode,
          isComponent: data?.platform === 'component',
        },
      });

      posthog.capture(POSTHOG_EVENTS.CHAT_MESSAGE, {
        webId: chatId,
        prompt: input,
        mode: mode,
      });

      // Clear integrations along with other resets
      setActiveIntegrations([]);
      setIsInspecting(false);
      clearInspectedElements();
      if (mode === 'build') {
        setIsInspecting(false);
        clearInspectedElements();
      }

      clearAttachments();
      setLocalStorageInput('');

      if (textareaRef.current) {
        textareaRef.current.style.height = '100px';
        textareaRef.current.focus();
      }

      if (width && width > 768) {
        textareaRef.current?.focus();
      }

      setAddIntegrations([]);
    }, [
      attachments,
      input,
      handleSubmit,
      chatId,
      clearAttachments,
      setLocalStorageInput,
      width,
      addIntegrations,
      setAddIntegrations,
      inspectedElements,
      clearInspectedElements,
      setIsInspecting,
      activeIntegrations,
      setActiveIntegrations,
      mode,
    ]);

    const handleStop = useCallback(async () => {
      try {
        // First stop the streaming
        stop();

        // Then cancel the generation with the correct project ID and isComponent flag
        await api.post('/api/web/cancel', {
          webId: data?._id || chatId,
          webVersionId: selectedVersion?._id,
          isComponent: data?.platform === 'component',
          mode: mode,
        });

        // Update the message in the UI
        setMessages(prev => {
          const lastAssistantIndex = prev.findLastIndex(
            m => m.role === 'assistant',
          );
          if (lastAssistantIndex === -1) return prev;

          const newMessages = [...prev];
          newMessages[lastAssistantIndex] = {
            ...newMessages[lastAssistantIndex],
            content: 'Generation stopped - all edits abandoned.',
            id: newMessages[lastAssistantIndex]?.id || '',
            role: 'assistant',
          };
          return newMessages;
        });

        if (mode === 'build' && iframeRef?.current) {
          iframeRef.current.src = iframeRef.current.src;
        }
        // Finally update in the database with correct project ID and isComponent flag
        await api.post('/api/web/messages/update-last-to-stopped', {
          webId: data?._id || chatId,
          isComponent: data?.platform === 'component',
        });

        // Clear integrations when stopping
        setActiveIntegrations([]);

        // Fetch latest edits info after stopping
        await fetchEditsInfo();
      } catch (error) {
        console.error('Error canceling web creation:', error);
        toast.error('Failed to cancel web creation');
      }
    }, [
      stop,
      chatId,
      selectedVersion,
      setMessages,
      data,
      iframeRef,
      fetchEditsInfo,
    ]);

    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        storeSetUploadQueue(acceptedFiles.map(file => file.name));

        try {
          const userToken = await getToken();
          const formData = new FormData();
          acceptedFiles.forEach(file => {
            formData.append('files', file);
          });

          const response = await api.post('/api/web/files/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          });

          if (response.data.success) {
            const uploadedFiles = Array.isArray(response.data.data)
              ? response.data.data
              : [response.data.data];

            const newAttachments = uploadedFiles.map(
              (fileData: FileUploadResponse) => ({
                url: fileData.url,
                name: fileData.pathname,
                contentType: fileData.contentType,
                id: v4(),
              }),
            );

            addAttachments(newAttachments);
          }
        } catch (error: any) {
          console.error('Error uploading files!', error);
          toast.error(
            error.response?.data?.message ||
              error.response?.data?.error?.message ||
              'Failed to upload files',
          );
        } finally {
          clearUploadQueue();
        }
      },
      [addAttachments, storeSetUploadQueue, clearUploadQueue],
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      accept: {
        'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
      },
      multiple: true,
      noClick: true,
      preventDropOnDocument: false,
      noDrag: false,
      onDragEnter: e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingFile(true);
      },
      onDragLeave: e => {
        e.preventDefault();
        e.stopPropagation();
        // Check if we're leaving the dropzone
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;

        if (
          x <= rect.left ||
          x >= rect.right ||
          y <= rect.top ||
          y >= rect.bottom
        ) {
          setIsDraggingFile(false);
        }
      },
      onDragOver: e => {
        e.preventDefault();
        e.stopPropagation();
      },
      onDropAccepted: () => {
        setIsDraggingFile(false);
      },
      onDropRejected: () => {
        setIsDraggingFile(false);
      },
      validator: file => {
        // Check file type
        if (!file.type.startsWith('image/')) {
          showErrorToast();
          return {
            code: 'file-invalid-type',
            message: 'Only image files are allowed',
          };
        }

        return null;
      },
    });

    // Update the document-level drag event handlers
    useEffect(() => {
      const handleDragEnter = (e: globalThis.DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(true);
      };

      const handleDragLeave = (e: globalThis.DragEvent) => {
        e.preventDefault();
        // Check if we're leaving the document entirely
        if (
          e.clientX <= 0 ||
          e.clientY <= 0 ||
          e.clientX >= window.innerWidth ||
          e.clientY >= window.innerHeight
        ) {
          setIsDraggingFile(false);
        }
      };

      const handleDrop = (e: globalThis.DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(false);
      };

      const handleDragOver = (e: globalThis.DragEvent) => {
        e.preventDefault();
      };

      // Add a dragend event listener to handle cases where the drag operation ends
      const handleDragEnd = (e: globalThis.DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(false);
      };

      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragleave', handleDragLeave);
      document.addEventListener('drop', handleDrop);
      document.addEventListener('dragover', handleDragOver);
      document.addEventListener('dragend', handleDragEnd);

      return () => {
        document.removeEventListener('dragenter', handleDragEnter);
        document.removeEventListener('dragleave', handleDragLeave);
        document.removeEventListener('drop', handleDrop);
        document.removeEventListener('dragover', handleDragOver);
        document.removeEventListener('dragend', handleDragEnd);
      };
    }, [setIsDraggingFile]);

    // Notify parent of drag state changes
    useEffect(() => {
      onDragActiveChange?.(isDragActive || isDraggingFile);
    }, [isDragActive, isDraggingFile, onDragActiveChange]);

    // Add useEffect to fetch files when component mounts and when needed
    useEffect(() => {
      const fetchFiles = async () => {
        if (isComponent && data?.currentCommitHash && data?.subdomain) {
          try {
            const response = await api.post('/git/commits/diff', {
              currentCommit: data.currentCommitHash,
              subdomain: data.subdomain,
              isSourceCode: true,
            });

            if (response.data?.files && Array.isArray(response.data.files)) {
              setFiles(response.data.files);
            }
          } catch (error) {
            console.error('Error fetching files:', error);
          }
        }
      };

      fetchFiles();
    }, [isComponent, data?.currentCommitHash, data?.subdomain]);

    const [previewImage, setPreviewImage] = useState<{
      url: string;
      name: string;
    } | null>(null);

    // 3. Clear on unmount
    useEffect(() => {
      return () => {
        setActiveIntegrations([]);
      };
    }, [setActiveIntegrations]);

    // Add useEffect to fetch edits info on mount
    useEffect(() => {
      fetchEditsInfo();
    }, [fetchEditsInfo]);

    if (!isOwner) {
      return (
        <div className="p-2">
          <div className="relative w-full rounded-xl border border-gray-200 bg-gray-100 p-4">
            <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
              <svg
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
              You don’t have the permission to edit this project.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} className="relative dark:bg-waitlist">
        <div
          {...getRootProps()}
          className="relative flex w-full flex-col rounded-xl p-2"
          onClick={e => e.stopPropagation()}
        >
          <input {...getInputProps()} />

          <div
            className={cn(
              'absolute inset-x-0 bottom-full transform transition-all duration-300 ease-out',
              isDragActive || isDraggingFile
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none translate-y-full opacity-0',
            )}
          >
            <div className="w-full rounded-lg border-2 border-dashed border-orange-400 bg-orange-50/90 p-6">
              <button
                onClick={e => {
                  e.stopPropagation();
                  setIsDraggingFile(false);
                }}
                className="absolute right-2 top-2 rounded-full p-1 text-orange-600 hover:bg-orange-100"
              >
                <X className="h-4 w-4" />
              </button>

              <div className="flex flex-col items-center gap-3">
                <div className="flex items-center justify-center gap-2">
                  <svg
                    className="h-5 w-5 rotate-[-4deg] text-orange-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="text-base font-medium text-orange-700">
                    Drop files here to add to chat
                  </span>
                  <svg
                    className="h-5 w-5 rotate-[4deg] text-orange-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                </div>
                <span className="text-sm text-orange-600">
                  Max 20 files per chat at 30 MB each
                </span>
              </div>
            </div>
          </div>

          <div className="w-full">
            {isOwner &&
            supabaseMigrations &&
            supabaseMigrations.filter(i => i.status === 'PENDING').length >
              0 ? (
              <SupabasePendingMigrations append={append} />
            ) : (
              <div className="relative flex flex-col gap-2 rounded-lg bg-[#F5F5F5] p-2 dark:bg-waitlist-foreground">
                {(attachments.length > 0 || uploadQueue.length > 0) && (
                  <div className="mb-2 flex flex-wrap items-end gap-2 rounded-lg bg-white p-2">
                    {attachments.map((attachment, index) => (
                      <div
                        key={`${attachment.url}-${index}`}
                        className="group relative flex flex-col gap-1"
                      >
                        <div
                          className="relative h-20 w-20 cursor-pointer overflow-hidden rounded-lg border border-gray-200"
                          onClick={() =>
                            setPreviewImage({
                              url: attachment.url,
                              name: attachment.name,
                            })
                          }
                        >
                          <img
                            src={attachment.url}
                            alt={attachment.name}
                            className="h-full w-full object-cover"
                          />
                          <button
                            onClick={e => {
                              e.stopPropagation(); // Prevent triggering the parent onClick
                              removeAttachment(attachment);
                            }}
                            className="absolute right-1 top-1 rounded-full bg-gray-900/60 p-1 opacity-0 transition-opacity group-hover:opacity-100 hover:bg-gray-900/80"
                          >
                            <X className="h-3 w-3 text-white" />
                          </button>
                        </div>
                        <span className="max-w-[80px] truncate text-[10px] text-gray-500">
                          {attachment.name}
                        </span>
                      </div>
                    ))}

                    {uploadQueue.map(filename => (
                      <div
                        key={filename}
                        className="relative flex flex-col gap-1"
                      >
                        <div className="relative h-20 w-20 overflow-hidden rounded-lg border border-gray-200 bg-gray-50">
                          <div className="absolute inset-0 flex items-center justify-center">
                            <Loader2 className="h-4 w-4 animate-spin text-gray-400" />
                          </div>
                        </div>
                        <span className="max-w-[80px] truncate text-[10px] text-gray-500">
                          {filename}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {inspectedElements.length > 0 && isInspecting ? (
                  <div className="flex flex-wrap items-center gap-1.5">
                    {inspectedElements.map((element, index) => (
                      <div
                        key={`${element.tagName}-${index}-${element.boundingBox.top}-${element.boundingBox.left}`}
                        className="flex items-center rounded-full bg-gray-800 px-3 py-1 text-xs font-medium text-gray-200 shadow"
                      >
                        <span>{element.tagName}</span>
                        <X
                          onClick={e => {
                            e.stopPropagation();
                            removeInspectedElement(element);
                            if (inspectedElements.length === 1) {
                              setIsInspecting(false);
                            }
                          }}
                          className="ml-1.5 h-3 w-3 cursor-pointer text-gray-400"
                        />
                      </div>
                    ))}
                    {inspectedElements.length > 1 && (
                      <button
                        onClick={e => {
                          e.stopPropagation();
                          clearInspectedElements();
                          setIsInspecting(false);
                        }}
                        className="ml-2 text-xs text-gray-500 hover:text-gray-700"
                      >
                        Clear all
                      </button>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="relative flex w-full items-center justify-between text-xs text-gray-400">
                      <div
                        className={cn(
                          'flex w-full items-center justify-between gap-2',
                          {
                            'justify-end': (editsInfo?.remaining || 100) <= 20,
                          },
                        )}
                      >
                        {(editsInfo?.remaining || 100) > 20 && (
                          <div className="text-xs">
                            Use{' '}
                            <span className="font-medium">Shift + Return</span>{' '}
                            for a new line
                          </div>
                        )}
                        <EditsCounter />
                      </div>
                      {isComponent && (
                        <div
                          className={cn(
                            'flex items-center gap-2 px-2 py-1',
                            isComponent && 'rounded-lg border bg-white shadow',
                          )}
                        >
                          {isComponent && !isLoading && (
                            <button
                              onClick={() =>
                                setIsSourceExpanded(!isSourceExpanded)
                              }
                              className="flex items-center gap-1 text-black transition-colors hover:text-gray-500"
                            >
                              <span>Code Blocks</span>
                              {isSourceExpanded ? (
                                <ChevronUp size={14} />
                              ) : (
                                <ChevronDown size={14} />
                              )}
                            </button>
                          )}
                          {isComponent && isLoading && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <button
                                    onClick={() =>
                                      setIsSourceExpanded(!isSourceExpanded)
                                    }
                                    className="flex items-center gap-1 text-black transition-colors hover:text-gray-500"
                                  >
                                    <span>Generating Code</span>
                                  </button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Code blocks will be generated here</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {isComponent && isSourceExpanded && !isLoading && (
                  <div className="overflow-hidden border-t border-zinc-200 transition-all dark:border-zinc-800">
                    <div className="transition-all duration-200 ease-out animate-in zoom-in-95">
                      <div className="max-h-[400px] overflow-auto py-4 scrollbar">
                        <div className="grid grid-cols-1 space-y-6">
                          {files?.map((file, index) => (
                            <CodeBlock
                              key={index}
                              code={file.newContent}
                              filename={file.path}
                              language={getLanguageFromPath(file.path)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="relative">
                  <Textarea
                    disabled={isApiLoading}
                    ref={textareaRef}
                    placeholder={
                      isApiLoading
                        ? 'Please wait for the webapp to load...'
                        : 'Ask Creatr...'
                    }
                    value={input}
                    onChange={handleInput}
                    onPaste={handlePaste}
                    className={cn(
                      'resize-none overflow-y-auto',
                      'rounded-lg border-none bg-white text-sm shadow-sm dark:bg-waitlist dark:shadow-xl',
                      'px-4 pt-3',
                      'pr-[60px]',
                      'placeholder:text-gray-500',
                      'scrollbar scrollbar-track-transparent scrollbar-thumb-gray-300',
                      'scrollbar-thin hover:scrollbar-thumb-gray-400',
                      'transition-all duration-200',
                      'max-h-[400px] min-h-[50px] md:min-h-[100px]',
                      className,
                      (isDragActive || isDraggingFile) && 'pointer-events-none',
                    )}
                    rows={1}
                    autoFocus
                    onKeyDown={event => {
                      if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        if (isLoading) {
                          toast.error(
                            'Please wait for the model to finish its response!',
                          );
                        } else {
                          submitForm();
                          // Reset height here as well to ensure it's always reset
                          if (textareaRef.current) {
                            textareaRef.current.style.height = '100px';
                          }
                        }
                      }
                    }}
                    onDragEnter={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDragLeave={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div>
                    {activeIntegrations.length > 0 && (
                      <div className="mt-2">
                        {activeIntegrations.map(integration => {
                          const integrationItem = INTEGRATIONS_LIST.find(
                            i => i.id === integration,
                          );
                          return (
                            <div
                              key={integration}
                              className="flex w-fit cursor-pointer items-center gap-1.5 rounded-lg border bg-[#F8FAFB]/70 px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm"
                            >
                              <img
                                src={integrationItem?.icon}
                                className="h-3"
                              />
                              {integrationItem?.name}
                              <X
                                onClick={() =>
                                  setActiveIntegrations(
                                    activeIntegrations.filter(
                                      i => i !== integration,
                                    ),
                                  )
                                }
                                className="ml-1 h-3 w-3 text-gray-500 hover:text-gray-700"
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="hidden pt-2 md:flex">
                    <div className="flex flex-wrap items-center gap-1">
                      <button
                        onClick={() => {
                          posthog.capture(POSTHOG_EVENTS.ENHANCE_PROMPT, {
                            prompt: input,
                            type: 'Chat',
                            webId: chatId ?? '',
                          });
                          return handleEnhancePrompt({
                            isGeneration: false,
                            webId: chatId,
                          });
                        }}
                        disabled={
                          !input.trim() ||
                          enhanceState === 'enhancing' ||
                          isLoading ||
                          isApiLoading
                        }
                        className={cn(
                          'flex w-fit items-center gap-1 rounded-lg border bg-[#F8FAFB] px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm',
                          (!input.trim() ||
                            enhanceState === 'enhancing' ||
                            isLoading ||
                            isApiLoading) &&
                            'cursor-not-allowed opacity-50',
                        )}
                      >
                        {enhanceState === 'enhancing' ? (
                          <>
                            <Loader2 size={12} className="animate-spin" />
                            <span className="hidden md:inline">
                              Enhancing...
                            </span>
                          </>
                        ) : enhanceState === 'enhanced' ? (
                          <>
                            <Sparkles size={12} />
                            <span className="hidden md:inline">Enhanced</span>
                          </>
                        ) : (
                          <>
                            <Wand2 size={12} />
                            <span className="hidden md:inline">Enhance</span>
                          </>
                        )}
                      </button>
                      {isOwner && (
                        <button
                          onClick={open}
                          disabled={isKnowledgeBaseModelOpen || isLoading}
                          className={cn(
                            'flex w-fit items-center gap-1 rounded-lg border bg-white px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm dark:border-border dark:bg-[#3b3b3b] dark:text-white',
                            {
                              'cursor-not-allowed opacity-50':
                                isKnowledgeBaseModelOpen || isLoading,
                            },
                          )}
                        >
                          <Paperclip size={10} />
                          <span className="hidden md:inline">Attach</span>
                        </button>
                      )}
                      {isOwner && inspirationsFF && !isComponent && (
                        <>
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                            disabled={isLoading}
                            className={cn(
                              'flex w-fit items-center gap-1 rounded-lg border bg-white px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm',
                              {
                                'cursor-not-allowed opacity-50': isLoading,
                              },
                            )}
                          >
                            <div className="relative">
                              <Stars size={12} />
                            </div>
                            <span className="hidden md:inline">
                              Inspirations
                            </span>
                          </button>
                          <ScreensModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                          />
                        </>
                      )}

                      {isOwner && (
                        <button
                          onClick={() => {
                            setIsKnowledgeBaseModelOpen(true);
                          }}
                          disabled={isKnowledgeBaseModelOpen || isLoading}
                          className={cn(
                            'flex w-fit items-center gap-1 rounded-lg border bg-white px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm',
                            {
                              'cursor-not-allowed opacity-50':
                                isKnowledgeBaseModelOpen || isLoading,
                            },
                          )}
                        >
                          <div className="relative">
                            <div
                              className={cn(
                                'absolute -right-[2px] -top-[2px] aspect-square h-[6px] rounded-full bg-green-600',
                                {
                                  hidden: !knowledgeBase?.length,
                                },
                              )}
                            ></div>
                            <BookOpen size={12} />
                          </div>
                          <span className="hidden md:inline">Knowledge</span>
                        </button>
                      )}

                      {/* Integrations button commented out
                      {isOwner && integrationsFF && !isComponent && (
                        <IntegrationsButton
                          integrations={addIntegrations}
                          setIntegrations={setAddIntegrations}
                          disabled={
                            enhanceState === "enhancing" ||
                            isLoading ||
                            isApiLoading
                          }
                        />
                      )}
                      */}
                      {isOwner && chatFF && (
                        <div className="flex items-center rounded-lg border bg-[#F8FAFB] text-xs">
                          <button
                            onClick={event => {
                              event.preventDefault();
                              if (mode !== 'build') {
                                setMode('build');
                              }
                            }}
                            className={cn(
                              'px-3 py-1 transition-all',
                              mode === 'build' || !mode
                                ? 'rounded-l-lg bg-orange-400 text-white'
                                : 'text-body-text hover:bg-[#dfe1e2]/50',
                            )}
                          >
                            Build
                          </button>
                          <button
                            onClick={event => {
                              event.preventDefault();
                              if (mode !== 'chat') {
                                setMode('chat');
                              }
                            }}
                            className={cn(
                              'px-3 py-1 transition-all',
                              mode === 'chat'
                                ? 'rounded-r-lg bg-orange-400 text-white'
                                : 'text-body-text hover:bg-[#dfe1e2]/50',
                            )}
                          >
                            Chat
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  {isLoading ? (
                    <button
                      className={cn(
                        'absolute right-2 top-2 h-fit rounded-lg border bg-gray-100 p-2 text-gray-600 shadow-md hover:bg-gray-200',
                        className,
                      )}
                      onClick={event => {
                        event.preventDefault();
                        handleStop();
                      }}
                    >
                      <StopIcon size={18} />
                    </button>
                  ) : (
                    <SendButton
                      disabled={isLoading}
                      input={input}
                      submitForm={submitForm}
                      uploadQueue={uploadQueue}
                      className="absolute right-2 top-2"
                    />
                  )}
                </div>
              </div>
            )}
            <div className="absolute bottom-0 hidden w-full flex-row items-center justify-between p-2 px-4">
              <AttachmentsButton isLoading={isLoading} onClick={open} />
              {inspectedElements.length > 0 && isInspecting ? (
                <div className="flex flex-wrap items-center gap-1.5">
                  {inspectedElements.map((element, index) => (
                    <div
                      key={`${element.tagName}-${index}-${element.boundingBox.top}-${element.boundingBox.left}`}
                      className="flex items-center rounded-full bg-gray-800 px-3 py-1 text-xs font-medium text-gray-200 shadow"
                    >
                      <span>{element.tagName}</span>
                      <X
                        onClick={e => {
                          e.stopPropagation();
                          removeInspectedElement(element);
                          if (inspectedElements.length === 1) {
                            setIsInspecting(false);
                          }
                        }}
                        className="ml-1.5 h-3 w-3 cursor-pointer text-gray-400"
                      />
                    </div>
                  ))}
                  {inspectedElements.length > 1 && (
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        clearInspectedElements();
                        setIsInspecting(false);
                      }}
                      className="ml-2 text-xs text-gray-500 hover:text-gray-700"
                    >
                      Clear all
                    </button>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex w-full items-center justify-between text-xs text-gray-400">
                    <div>
                      Use <span className="font-medium">Shift + Return</span>{' '}
                      for a new line
                    </div>
                    <EditsCounter />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ImagePreviewModal
          isOpen={previewImage !== null}
          onClose={() => setPreviewImage(null)}
          imageUrl={previewImage?.url || ''}
          imageName={previewImage?.name || ''}
        />
      </div>
    );
  },
);

export const MultimodalInput = memo(
  PureMultimodalInput,
  (prevProps, nextProps) => {
    if (prevProps.input !== nextProps.input) return false;
    if (prevProps.isOwner !== nextProps.isOwner) return false;
    if (prevProps.mode !== nextProps.mode) return false;
    return true;
  },
);

function PureAttachmentsButton({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      className="h-fit rounded-md rounded-bl-lg p-[7px] text-gray-600 hover:bg-zinc-200 hover:dark:bg-zinc-900"
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
      disabled={isLoading}
      variant="ghost"
    >
      <PaperclipIcon size={14} />
    </Button>
  );
}

const AttachmentsButton = memo(PureAttachmentsButton);

function PureStopButton({
  stop,
  setMessages,
}: {
  stop: () => void;
  setMessages: Dispatch<SetStateAction<Array<Message>>>;
}) {
  return (
    <Button
      className="h-fit rounded-full p-1.5 dark:border-zinc-600"
      onClick={event => {
        event.preventDefault();
        stop();
        setMessages(messages => messages);
      }}
    >
      <StopIcon size={14} />
    </Button>
  );
}

const StopButton = memo(PureStopButton);

function PureSendButton({
  submitForm,
  input,
  uploadQueue,
  className,
  disabled = false,
}: {
  submitForm: () => void;
  input: string;
  uploadQueue: Array<string>;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <button
      className={cn(
        'special-btn h-fit rotate-90 rounded-lg border bg-orange-400 p-2 text-white shadow-md hover:bg-orange-500 dark:border-zinc-600',
        className,
        (disabled || input.length === 0 || uploadQueue.length > 0) &&
          'cursor-not-allowed opacity-50',
      )}
      onClick={event => {
        event.preventDefault();
        submitForm();
      }}
      disabled={input.length === 0 || uploadQueue.length > 0 || disabled}
    >
      <ArrowUpIcon size={18} />
    </button>
  );
}

const SendButton = memo(PureSendButton, (prevProps, nextProps) => {
  if (prevProps.uploadQueue.length !== nextProps.uploadQueue.length)
    return false;
  if (prevProps.input !== nextProps.input) return false;
  return true;
});
