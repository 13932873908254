'use client';

import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { Button } from './button';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';

type CodeBlockProps = {
  language: string;
  filename: string;
  highlightLines?: number[];
  code: string;
} & (
  | {
      code: string;
      tabs?: never;
    }
  | {
      code?: never;
      tabs: Array<{
        name: string;
        code: string;
        language?: string;
        highlightLines?: number[];
      }>;
    }
);

export const CodeBlock = ({
  language,
  filename,
  code,
  highlightLines = [],
  tabs = [],
}: CodeBlockProps) => {
  const [copied, setCopied] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const tabsExist = tabs.length > 0;

  const copyToClipboard = async () => {
    const textToCopy = tabsExist ? tabs[activeTab].code : code;
    if (textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        toast.success('Copied to clipboard');
        setTimeout(() => setCopied(false), 2000);
      } catch (error) {
        toast.error('Failed to copy code');
        console.error('Failed to copy:', error);
      }
    }
  };

  const activeCode = tabsExist ? tabs[activeTab].code : code;
  const activeLanguage = tabsExist
    ? tabs[activeTab].language || language
    : language;
  const activeHighlightLines = tabsExist
    ? tabs[activeTab].highlightLines || []
    : highlightLines;

  return (
    <div className="relative w-full rounded-lg bg-slate-900 p-4 py-1 font-mono text-sm">
      <div className="flex flex-col gap-2">
        {tabsExist && (
          <div className="flex overflow-x-auto">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={`!py-2 px-3 text-xs transition-colors ${
                  activeTab === index
                    ? 'text-white'
                    : 'text-zinc-400 hover:text-zinc-200'
                }`}
              >
                {tab.name}
              </button>
            ))}
          </div>
        )}
        {!tabsExist && filename && (
          <div className="mb-2 flex items-center justify-between border-b border-slate-700 px-1 py-2">
            <div className="flex items-center gap-2">
              <span className="font-mono text-sm font-semibold text-slate-300">
                {filename}
              </span>
            </div>
            <button
              onClick={copyToClipboard}
              className="flex items-center gap-1 rounded-full p-2 text-xs text-zinc-200 transition-all transition-colors hover:bg-slate-800 hover:text-zinc-200"
            >
              {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
            </button>
          </div>
        )}
      </div>

      <div className="relative">
        <div
          className={cn(
            'syntax-highlighter-container codeblock-scrollbar relative transition-all duration-200',
            !isExpanded && 'max-h-[300px] overflow-hidden',
          )}
        >
          <SyntaxHighlighter
            language={activeLanguage}
            style={atomDark}
            customStyle={{
              margin: 0,
              padding: 0,
              background: 'transparent',
              fontSize: '0.875rem',
            }}
            wrapLines={true}
            showLineNumbers={true}
            lineProps={lineNumber => ({
              style: {
                backgroundColor: activeHighlightLines.includes(lineNumber)
                  ? 'rgba(255,255,255,0.1)'
                  : 'transparent',
                display: 'block',
                width: '100%',
              },
            })}
            PreTag="div"
          >
            {String(activeCode)}
          </SyntaxHighlighter>

          {!isExpanded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-slate-900/50 to-slate-900" />
              <Button
                onClick={() => setIsExpanded(true)}
                className="relative z-10 bg-white text-zinc-900 hover:bg-white/90"
              >
                Expand
              </Button>
            </div>
          )}
        </div>

        {isExpanded && (
          <div className="mt-4 flex w-full justify-center border-t border-slate-800 py-2">
            <Button
              variant="ghost"
              onClick={() => setIsExpanded(false)}
              className="text-xs text-slate-400 hover:text-slate-800"
            >
              Collapse
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
