import React, { useState } from 'react';
import { ProjectCreationSteps, useWebappStore } from '@/store/useWebappData';
import { Button } from '@/components/ui/button';
import {
  AlertCircle,
  Check,
  Copy,
  Loader2,
  Maximize2,
  Minimize2,
  X,
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { cn } from '@/lib/utils';
import api from '@/lib/axios';
import { useAuth } from '@clerk/nextjs';

const formatSql = (sql: string) => {
  return sql
    .split('\n')
    .map(line => line.trim())
    .filter(line => line)
    .join('\n');
};

const parseMarkdownList = (text: string) => {
  const sections: { title: string; items: string[] }[] = [];
  let currentSection: { title: string; items: string[] } | null = null;

  text.split('\n').forEach(line => {
    const trimmedLine = line.trim();

    // Check for numbered section
    if (/^\d+\.\s/.test(trimmedLine)) {
      if (currentSection) {
        sections.push(currentSection);
      }
      currentSection = {
        title: trimmedLine.replace(/^\d+\.\s/, ''),
        items: [],
      };
    }
    // Check for list item
    else if (trimmedLine.startsWith('-')) {
      if (currentSection) {
        currentSection.items.push(trimmedLine.substring(1).trim());
      }
    }
  });

  if (currentSection) {
    sections.push(currentSection);
  }

  return sections;
};

const MigrationContent = ({ script }: { script: string }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const commentMatch = script.match(/\/\*([\s\S]*?)\*\//);
  const description = commentMatch ? commentMatch[1].trim() : '';
  const sqlContent = commentMatch
    ? script.substring(commentMatch[0].length).trim()
    : script;
  const sections = parseMarkdownList(description);
  const title = description?.split('\n')[0].replace('#', '').trim() ?? '';

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(sqlContent);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className={`relative w-full`}>
      <Tabs defaultValue="description" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="description">Description</TabsTrigger>
          <TabsTrigger value="sql">SQL</TabsTrigger>
        </TabsList>
        <TabsContent value="description" className="mt-2">
          <ScrollArea
            className={`h-32 rounded-lg border border-gray-200 bg-white p-4`}
          >
            <h3 className="mb-4 text-lg font-semibold text-gray-900">
              {title}
            </h3>
            <div className="space-y-4">
              {sections.map((section, idx) => (
                <div key={idx} className="space-y-2">
                  <h4 className="font-medium text-gray-700">{section.title}</h4>
                  <ul className="space-y-1 pl-4">
                    {section.items.map((item, itemIdx) => (
                      <li key={itemIdx} className="text-gray-600">
                        {item.includes('`') ? (
                          <span className="font-mono">{item}</span>
                        ) : (
                          item
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </ScrollArea>
        </TabsContent>
        <TabsContent value="sql" className="relative mt-2">
          <div className="absolute right-2 top-2 z-10 flex gap-2">
            <button
              onClick={handleCopy}
              className="rounded-md border border-gray-500 bg-transparent p-2 text-white transition-all hover:bg-gray-200 hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              <Copy className="h-4 w-4" />
            </button>
          </div>
          <ScrollArea className={`h-32 rounded-lg bg-gray-900 p-4`}>
            <pre className="whitespace-pre-wrap font-mono text-sm text-gray-100">
              {formatSql(sqlContent)}
            </pre>
          </ScrollArea>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const MigrationItem = ({
  migration,
  append,
}: {
  migration: {
    id: string;
    status: 'PENDING' | 'APPLIED' | 'DISCARDED';
    appliedAt?: Date;
    discardedAt?: Date;
    script: string;
    error?: string;
    logs?: {
      message: string;
      timestamp: Date;
    };
  };
  append: any;
}) => {
  const {
    data,
    updateSupabaseMigrationStatus,
    isLoading: isApiLoading,
  } = useWebappStore();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [action, setAction] = React.useState<'accept' | 'discard' | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [contentLoading, setContentLoading] = React.useState(false);
  const { getToken } = useAuth();
  const [showError, setShowError] = React.useState(true);

  const projectStatus = data?.phase || null;
  const isAiChatLoading = isApiLoading
    ? false
    : projectStatus !== ProjectCreationSteps.COMPLETE &&
      projectStatus !== ProjectCreationSteps.FAILED;

  const handleDiscardMigration = async ({
    webappId,
    migrationId,
  }: {
    webappId: string;
    migrationId: string;
  }) => {
    try {
      setIsLoading(true);
      setShowError(false);
      setError(null);

      const token = await getToken();

      const response = await api.post(
        '/api/supabase/discard-migration',
        {
          webappId: webappId ?? '',
          migrationId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // Check if response status is not in 2xx range
      if (!response.status.toString().startsWith('2')) {
        const errorData = await response.data;
        throw new Error(errorData.error || 'Failed to discard migration');
      }

      // Update the migration status in the UI
      updateSupabaseMigrationStatus(migrationId, 'DISCARDED');

      // Reset UI state
      setShowConfirm(false);

      return { success: true };
    } catch (err) {
      setShowError(true);

      // Log the error details for debugging
      console.error('Error discarding migration:', err);

      // Set appropriate error message
      const errorMessage =
        err instanceof Error
          ? err?.response?.data?.details
          : 'An unknown error occurred';

      setError(errorMessage);

      return {
        success: false,
        error: errorMessage,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async () => {
    try {
      setIsLoading(true);
      setShowError(false);
      setError(null);
      const token = await getToken();

      const endpoint =
        action === 'accept'
          ? '/api/supabase/accept-migration'
          : '/api/supabase/discard-migration';

      const response = await api.post(
        endpoint,
        {
          webappId: data?._id ?? '',
          migrationId: migration.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.status.toString().startsWith('2')) {
        const errorData = await response.data;
        throw new Error(errorData.error || 'Failed to process migration');
      }

      updateSupabaseMigrationStatus(
        migration.id,
        action === 'accept' ? 'APPLIED' : 'DISCARDED',
      );

      setShowConfirm(false);
      setAction(null);
    } catch (err) {
      setShowError(true);
      console.log(err.response.data);
      setError(
        err instanceof Error
          ? err?.response.data.details
          : 'An unknown error occurred',
      );
      console.error('Error processing migration:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHideError = () => {
    setShowError(false);
  };

  const fixMigrationError = async (
    migration: {
      id: string;
      status: 'PENDING' | 'APPLIED' | 'DISCARDED';
      appliedAt?: Date;
      discardedAt?: Date;
      script: string;
      error?: string;
      logs?: {
        message: string;
        timestamp: Date;
      };
    },
    error: string | null,
  ) => {
    try {
      await handleDiscardMigration({
        webappId: data?._id || '',
        migrationId: migration.id,
      });
      append(
        {
          role: 'user',
          content: `Fix migration error in \n\n ${migration.error} \n\n ${error}`,
        },
        {
          body: {
            webId: data?._id || '',
            integrations: [],
          },
        },
      );
    } catch (error) {
      console.error('Failed to fix migration error:', error);
    }
  };

  const statusColor = {
    PENDING: 'text-yellow-500',
    APPLIED: 'text-green-500',
    DISCARDED: 'text-red-500',
  }[migration.status];

  if (contentLoading) {
    return (
      <CarouselItem className="pl-6">
        <div className="overflow-hidden rounded-lg border border-gray-200">
          <div className="flex h-32 items-center justify-center bg-white">
            <div className="flex items-center gap-2">
              <svg className="h-5 w-5 animate-spin" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              <span className="text-sm text-gray-500">
                Loading migration...
              </span>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  }

  return (
    <CarouselItem className="pl-6">
      <div className="overflow-hidden rounded-lg border border-gray-200">
        <div className="flex items-center justify-between bg-white p-4 py-2">
          <div className="flex items-center gap-4">
            <span className={cn('text-sm font-medium', statusColor)}>
              {migration.status} MIGRATION
            </span>
          </div>

          {migration.status === 'PENDING' && isLoading ? (
            <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
          ) : (
            <div className="flex gap-2">
              <Button
                size="sm"
                className="rounded-xl bg-orange-400 text-white hover:bg-orange-100 hover:text-green-700"
                onClick={() => {
                  setAction('accept');
                  setShowConfirm(true);
                }}
              >
                <Check className="mr-1 h-4 w-4" />
                Accept
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="rounded-xl text-red-600 hover:bg-red-50 hover:text-red-700"
                onClick={() => {
                  setAction('discard');
                  setShowConfirm(true);
                }}
              >
                <X className="mr-1 h-4 w-4" />
                Discard
              </Button>
            </div>
          )}
        </div>

        <div className="border-t border-gray-100 p-4">
          <MigrationContent script={migration.script} />
        </div>

        {(migration.error || error) && showError && (
          <div className="border-t border-red-100 bg-red-50 p-4">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex items-center gap-2 text-red-600">
                <AlertCircle className="h-4 w-4" />
                <span className="font-medium">Error</span>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleHideError}
                className="h-6 rounded-lg hover:bg-red-100"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <pre className="whitespace-pre-wrap text-sm text-red-700">
              {migration.error || error}
            </pre>
            <Button
              variant="ghost"
              className="mt-2 border border-green-800 bg-green-800 text-white"
              disabled={isAiChatLoading}
              onClick={() =>
                fixMigrationError(migration, migration.error || error)
              }
            >
              Fix
            </Button>
          </div>
        )}

        <AlertDialog open={showConfirm} onOpenChange={setShowConfirm}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                {action === 'accept' ? 'Accept Migration' : 'Discard Migration'}
              </AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to {action} this migration? This action
                cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  setAction(null);
                  setError(null);
                }}
                disabled={isLoading}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={handleAction}
                disabled={isLoading}
                className={cn(
                  isLoading && 'cursor-not-allowed opacity-50',
                  action === 'accept' && 'bg-orange-400 hover:bg-orange-500',
                  action === 'discard' && 'bg-red-500 hover:bg-red-600',
                )}
              >
                {isLoading ? (
                  <span className="flex items-center gap-2">
                    <svg className="h-4 w-4 animate-spin" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Processing...
                  </span>
                ) : (
                  'Confirm'
                )}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </CarouselItem>
  );
};

const SupabasePendingMigrations = ({ append }: { append: any }) => {
  const { getMigrations } = useWebappStore();
  const migrations = getMigrations();
  const [api, setApi] = React.useState<any>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  if (migrations.filter(i => i.status === 'PENDING').length === 0) {
    return (
      <div className="flex items-center justify-center p-8 text-gray-500">
        <AlertCircle className="mr-2 h-5 w-5" />
        No migrations found
      </div>
    );
  }

  return (
    <div className="relative mx-auto w-full">
      <Carousel
        setApi={setApi}
        className="w-full"
        opts={{
          align: 'start',
          loop: false,
          dragFree: false,
          containScroll: 'trimSnaps',
        }}
      >
        <CarouselContent className="-ml-6">
          {migrations
            .filter(i => i.status === 'PENDING')
            .sort((migrationA, migrationB) => {
              if (migrationA.status === 'PENDING') {
                return -1;
              }
              return 1;
            })
            .map(migration => (
              <MigrationItem
                key={migration.id}
                migration={migration}
                append={append}
              />
            ))}
        </CarouselContent>
        <CarouselPrevious className="left-2" />
        <CarouselNext className="right-2" />
      </Carousel>
      <div className="py-2 text-center text-sm text-muted-foreground">
        Migration {current} of {count}
      </div>
    </div>
  );
};

export default SupabasePendingMigrations;
