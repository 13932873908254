import { create } from 'zustand';

export type WebApp = {
  appName: string;
  appTagline: string;
  appLogoUrl: string;
  appCategory: string;
  previewScreens: {
    id: string;
    screenUrl: string;
  }[];
};

export type WebScreen = {
  appName: string;
  screenUrl: string;
  appTagline: string;
  appLogoUrl: string;
  appCategory: string;
  screenPatternsForAll: string[];
  pagePatterns: string[];
  screenElements: string[];
};

interface InspirationState {
  allApps: WebApp[];
  allScreens: WebScreen[];
  screensByCategory: Record<string, WebScreen[]>;
  selectedScreens: WebScreen[];
  setAllApps: (apps: WebApp[]) => void;
  setAllScreens: (screens: WebScreen[]) => void;
  setScreensForCategory: (category: string, screens: WebScreen[]) => void;
  toggleScreenSelection: (screen: WebScreen) => void;
  clearSelectedScreens: () => void;
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
}

export const useInspirationStore = create<InspirationState>(set => ({
  allApps: [], // For apps tab
  allScreens: [], // For other tabs
  screensByCategory: {}, // Cache screens by category
  selectedScreens: [],
  isFetching: false,
  setAllApps: apps => set({ allApps: apps }),
  setAllScreens: screens => set({ allScreens: screens }),
  setScreensForCategory: (category, screens) =>
    set(state => ({
      screensByCategory: {
        ...state.screensByCategory,
        [category]: screens,
      },
    })),
  toggleScreenSelection: screen =>
    set(state => {
      const isSelected = state.selectedScreens.some(
        s => s.screenUrl === screen.screenUrl,
      );
      if (isSelected) {
        return {
          selectedScreens: state.selectedScreens.filter(
            s => s.screenUrl !== screen.screenUrl,
          ),
        };
      }
      if (state.selectedScreens.length >= 5) return state;
      return {
        selectedScreens: [...state.selectedScreens, screen],
      };
    }),
  clearSelectedScreens: () => set({ selectedScreens: [] }),
  setIsFetching: isFetching => set({ isFetching }),
}));

export const tabConfig = {
  screens: {
    filters: [
      'All',
      'Account Setup',
      'Achievements & Awards',
      'Acknowledgement & Success',
      'Action Option',
      'Add & Create',
      'Article Detail',
      'Audio & Video Recorder',
      'Audio Player',
      'Ban & Block',
      'Billing',
      'Bookmarks & Collections',
      'Browse & Discover',
      'Calendar',
      'Call',
      'Cancel',
      'Canvas',
      'Cart & Bag',
      'Charts',
      'Chat Bot',
      'Chat Detail',
      'Checkout',
      'Class & Lesson Detail',
      'Code Editor',
      'Command Palette',
      'Comments',
      'Confirmation',
      'Dark Mode',
      'Dashboard',
      'Date & Time',
      'Delete',
      'Delete & Deactivate Account',
      'Draw & Annotate',
      'Edit',
      'Emails & Messages',
      'Empty State',
      'Error',
      'Event Detail',
      'Favorite & Pin',
      'Feature Info',
      'Feedback',
      'Filter & Sort',
      'Flag & Report',
      'Follow & Subscribe',
      'Followers & Following',
      'Forgot Password',
      'Goal & Task',
      'Guided Tour & Tutorial',
      'Help & Support',
      'Home',
      'Import & Export',
      'Internal Tool',
      'Invite & Refer Friends',
      'Invite Teammates',
      'Kanban Board',
      'Leaderboard',
      'Like & Upvote',
      'Loading',
      'Login',
      'Map',
      'Media Editor',
      'Misc',
      'Move',
      'Multi-Column Layout',
      'My Account & Profile',
      'News Feed',
      'Note Detail',
      'Notifications',
      'Order Confirmation',
      'Order Detail',
      'Order History',
      'Other Action',
      'Other Content',
      'Payment Method',
      'Permission',
      'Playlists',
      'Post Detail',
      'Pricing',
      'Product Detail',
      'Progress',
      'Promotions & Rewards',
      'Publish',
      'QR Code',
      'Quiz',
      'Recipe Detail',
      'Reorder',
      'Reviews & Ratings',
      'Save',
      'Schedule',
      'Search',
      'Select',
      'Set',
      'Settings & Preferences',
      'Share',
      'Shop & Storefront',
      'Signup',
      'Social Feed',
      'Song & Podcast Detail',
      'Stories',
      'Subscription & Paywall',
      'Suggestions & Similar Items',
      'TV Show & Movie Detail',
      'Timeline & History',
      'Timer & Clock',
      'Transfer & Send Money',
      'Trash & Archive',
      'Upload & Download',
      'User / Group Profile',
      'Verification',
      'Video Player',
      'Wallet & Balance',
    ],
    searchPlaceholder: 'Search screens by pattern...',
  },
  apps: {
    filters: [
      'All',
      'Artificial Intelligence',
      'Business',
      'CRM',
      'Collaboration',
      'Communication',
      'Crypto & Web3',
      'Developer Tools',
      'Education',
      'Entertainment',
      'Finance',
      'Food & Drink',
      'Graphics & Design',
      'Health & Fitness',
      'Jobs & Recruitment',
      'Lifestyle',
      'Maps & Navigation',
      'Medical',
      'Music & Audio',
      'News',
      'Photo & Video',
      'Productivity',
      'Real Estate',
      'Reference',
      'Shopping',
      'Social Networking',
      'Sports',
      'Travel & Transportation',
      'Utilities',
    ],
    searchPlaceholder: 'Search by app name...',
  },
  marketing: {
    filters: [
      'All',
      '404 Page',
      'API Docs & Developers Page',
      'About Page',
      'Affiliation & Partner Program Page',
      'Blog Page',
      'Blog Post',
      'Blog Section',
      'CTA Section',
      'Careers Page',
      'Community Page',
      'Competitor Comparison Page',
      'Contact Page',
      'Contact Section',
      'Content Section',
      'Download App Section',
      'Downloads Page',
      'Enterprise Page',
      'Events & Webinar Page',
      'FAQ Section',
      'Feature Section',
      'Features Page',
      'Footer Section',
      'Gifting Page',
      'Guides & Tutorials Page',
      'Header Section',
      'Hero Section',
      'Integrations Page',
      'Landing Page',
      'Misc',
      'Newsletter & Waitlist Page',
      'Newsletter Section',
      'Press & Media Page',
      'Pricing Page',
      'Pricing Section',
      'Releases & Changelog Page',
      'Social Proof (Logo Cloud) Section',
      'Social Proof (Testimonial) Section',
      'Solutions & Use Cases Page',
      'Stats Section',
      'Support & Help Center Page',
      'System Status Page',
      'Team Section',
      'Templates Page',
      'Terms & Privacy Page',
      'Testimonials & Customer Stories Page',
    ],
    searchPlaceholder: 'Search marketing pages...',
  },
  ui: {
    filters: [
      'All',
      'Accordion',
      'Avatar',
      'Badge',
      'Banner',
      'Breadcrumbs',
      'Button',
      'Card',
      'Carousel',
      'Checkbox',
      'Chip',
      'Coach Marks',
      'Color Picker',
      'Combobox',
      'Context Menu',
      'Date Picker',
      'Dialog',
      'Divider',
      'Drawer',
      'Dropdown Menu',
      'Editable Text',
      'File Upload',
      'Floating Action Button',
      'Full-Screen Overlay',
      'Gallery',
      'Grid List',
      'Icon',
      'Illustration',
      'Keyboard Key',
      'Link',
      'Loading Indicator',
      'Logo',
      'Map Pin',
      'Navigation Menu',
      'Pagination',
      'Photo',
      'Popover',
      'Progress Indicator',
      'Radio Button',
      'Rating Control',
      'Search Bar',
      'Segmented Control',
      'Select',
      'Side Navigation',
      'Skeleton',
      'Slider',
      'Stacked List',
      'Status Dot',
      'Stepper',
      'Switch',
      'Tab',
      'Table',
      'Table of Contents',
      'Text Field',
      'Tile',
      'Time Picker',
      'Toast',
      'Toolbar',
      'Tooltip',
      'Top Navigation Bar',
      'Tree',
      'Trial Bar',
    ],
    searchPlaceholder: 'Search UI elements...',
  },
};
