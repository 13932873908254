import { useEditsStore } from '@/store/useEditsStore';
import { useAuth } from '@clerk/nextjs';
import { api } from '@/lib/axios';
import { useWebappStore } from '@/store/useWebappData';
import { useCallback } from 'react';

interface ProjectEditsResponse {
  success: boolean;
  data: {
    isPremium: boolean;
    editsInfo: {
      remaining: number;
      total: number;
      used: number;
    };
  };
}

export const useEditsInfo = () => {
  const { editsInfo, setEditsInfo } = useEditsStore();
  const { getToken } = useAuth();
  const { data: webappData } = useWebappStore();
  const projectId = webappData?._id;

  const fetchEditsInfo = useCallback(async () => {
    if (!projectId) return;

    try {
      const token = await getToken();
      const response = await api.post<ProjectEditsResponse>(
        '/api/stripe/getProjectEdits',
        { projectId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.success && response.data.data.editsInfo) {
        setEditsInfo({
          ...response.data.data.editsInfo,
          isPremium: response.data.data.isPremium,
        });
      }
    } catch (error) {
      console.error('Failed to fetch edits info:', error);
    }
  }, [projectId, getToken, setEditsInfo]);

  const updateEditsInfo = useCallback(
    (newEditsInfo: {
      remaining: number | null;
      total: number | null;
      used: number;
    }) => {
      if (newEditsInfo) {
        // If remaining and total are null, it's a premium user
        const isPremium =
          newEditsInfo.remaining === null && newEditsInfo.total === null;
        setEditsInfo({
          ...newEditsInfo,
          isPremium,
        });
      }
    },
    [setEditsInfo],
  );

  return {
    editsInfo,
    fetchEditsInfo,
    updateEditsInfo,
  };
};
