import { Dialog, DialogContent } from './Dialog';
import { X } from 'lucide-react';

interface ImagePreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  imageName: string;
}

export const ImagePreviewModal = ({
  isOpen,
  onClose,
  imageUrl,
  imageName,
}: ImagePreviewModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl overflow-hidden bg-black/80 p-0">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 z-50 rounded-full bg-black/50 p-2 text-white transition-colors hover:bg-black/70"
        >
          <X className="h-5 w-5" />
        </button>
        <div className="relative flex flex-col items-center">
          <img
            src={imageUrl}
            alt={imageName}
            className="max-h-[80vh] w-auto object-contain"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-2 text-sm text-white">
            {imageName}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
