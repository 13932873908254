import { create } from 'zustand';

interface EditsInfo {
  remaining: number | null;
  total: number | null;
  used: number;
  isPremium?: boolean;
}

interface EditsState {
  editsInfo: EditsInfo | null;
  setEditsInfo: (info: EditsInfo | null) => void;
}

export const useEditsStore = create<EditsState>(set => ({
  editsInfo: null,
  setEditsInfo: info => set({ editsInfo: info }),
}));
