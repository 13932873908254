import React, { useState } from 'react';
import { Tab } from '../PricingSectionTab';

const PAYMENT_FREQUENCIES = ['monthly', 'annual'] as const;

const BillingToggle = ({
  selectedBilling,
  setSelectedBilling,
}: {
  selectedBilling: 'monthly' | 'annual';
  setSelectedBilling: (a: 'monthly' | 'annual') => void;
}) => {
  return (
    <>
      <div className="mx-auto flex w-fit rounded-full bg-zinc-200 p-1">
        {PAYMENT_FREQUENCIES.map(freq => (
          <Tab
            key={freq}
            text={freq}
            selected={selectedBilling === freq}
            setSelected={setSelectedBilling}
            discount={freq === 'annual'}
          />
        ))}
      </div>
    </>
  );
};

export default BillingToggle;
