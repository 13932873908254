import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/Dialog';
import {
  useInspirationStore,
  tabConfig,
  WebScreen,
  WebApp,
} from '@/store/useInspirationStore';
import {
  Search,
  X,
  SlidersHorizontal,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Check,
  Eye,
  Plus,
} from 'lucide-react';
import Image from 'next/image';
import { useState, useRef, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import api from '@/lib/axios';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { useAttachmentsStore } from '@/store/useAttachmentsStore';

interface ScreensModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInsert?: (screens: WebScreen[]) => void;
}

const tabs = [
  { id: 'apps', label: 'Apps' },
  // { id: 'screens', label: 'Screens' },
  // { id: 'marketing', label: 'Marketing Pages' },
  // { id: 'ui', label: 'UI Elements' },
];

interface TabContentProps {
  searchQuery: string;
  selectedCategory: string;
}

interface SharedContentProps extends TabContentProps {
  activeTab: string;
  onAppClick: (appName: string) => void;
}

const SharedContent = ({
  searchQuery,
  selectedCategory,
  activeTab,
  onAppClick,
}: SharedContentProps) => {
  const {
    allApps,
    allScreens,
    screensByCategory,
    setAllApps,
    setScreensForCategory,
    isFetching,
    setIsFetching,
    selectedScreens,
    toggleScreenSelection,
  } = useInspirationStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchScreensForCategory = async (
    category: string,
    page: number = 1,
  ) => {
    setIsFetching(true);
    try {
      let endpoint = '/api/web-screens';
      let method = 'post';
      let params: Record<string, any> = { limit: 10, page };

      switch (activeTab) {
        case 'screens':
          endpoint =
            category === 'All'
              ? '/api/web-screens/getRandomScreens'
              : '/api/web-screens/getScreensByScreenPatterns';
          params = {
            ...params,
            screenPatternsForAll: [category],
            type: 'screen',
          };
          break;
        case 'apps':
          if (searchQuery) {
            endpoint = '/api/web-screens/searchApps';
            params = {
              ...params,
              searchTerm: searchQuery,
              category: category === 'All' ? undefined : category,
            };
          } else {
            endpoint =
              category === 'All'
                ? '/api/web-screens/getRandomScreens'
                : '/api/web-screens/getScreensByCategory';
            params = {
              ...params,
              category,
              type: 'apps',
            };
          }
          break;
        case 'marketing':
          endpoint =
            category === 'All'
              ? '/api/web-screens/getRandomScreens'
              : '/api/web-screens/getScreensByPagePatterns';
          params = {
            ...params,
            pagePatterns: [category],
            type: 'marketing',
          };
          break;
        case 'ui':
          endpoint =
            category === 'All'
              ? '/api/web-screens/getRandomScreens'
              : '/api/web-screens/getScreensByElements';
          params = {
            ...params,
            screenElements: [category],
            type: 'ui',
          };
          break;
      }

      const { data } = await api.post(endpoint, params);
      if (data && data.data) {
        setHasMore(data.data.length === 10); // Assuming 10 is your page size
        if (activeTab === 'apps') {
          setAllApps(page === 1 ? data.data : [...allApps, ...data.data]);
        } else if (category === 'All') {
          setAllApps(page === 1 ? data.data : [...allScreens, ...data.data]);
        } else {
          setScreensForCategory(
            category,
            page === 1
              ? data.data
              : [...(screensByCategory[category] || []), ...data.data],
          );
        }
      }
    } catch (error) {
      console.error('Error fetching screens:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchScreensForCategory(selectedCategory, 1);
  }, [selectedCategory, searchQuery]);

  const getScreensToDisplay = () => {
    if (activeTab === 'apps') {
      return allApps;
    }

    const screens =
      selectedCategory === 'All'
        ? allScreens
        : screensByCategory[selectedCategory] ||
          allScreens.filter(screen => screen.appCategory === selectedCategory);

    // Apply tab-specific filtering
    return screens.filter(screen => {
      const searchLower = searchQuery.toLowerCase();

      switch (activeTab) {
        case 'screens':
          return screen.screenPatternsForAll.some(pattern =>
            pattern.toLowerCase().includes(searchLower),
          );
        case 'apps':
          return screen.appName.toLowerCase().includes(searchLower);
        case 'marketing':
          return screen.pagePatterns.some(pattern =>
            pattern.toLowerCase().includes(searchLower),
          );
        case 'ui':
          return screen.screenElements.some(element =>
            element.toLowerCase().includes(searchLower),
          );
        default:
          return true;
      }
    });
  };

  const filteredScreens = getScreensToDisplay();

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 xl:grid-cols-3">
        {isFetching && currentPage === 1 && (
          <div className="col-span-full flex justify-center">
            <div className="loading-spinner">Loading...</div>
          </div>
        )}

        {!isFetching && filteredScreens.length === 0 && (
          <div className="col-span-full py-12 text-center">
            <h3 className="text-xl font-medium text-muted-foreground">
              No screens found for this category
            </h3>
          </div>
        )}

        {filteredScreens.map(item => {
          // For apps tab, render WebApp data
          if (activeTab === 'apps') {
            const app = item as WebApp;
            return (
              <div
                key={app.appName}
                className="group flex cursor-pointer flex-col gap-4"
                onClick={() => onAppClick(app.appName)}
              >
                <div className="relative aspect-[4/3] w-full overflow-hidden rounded-2xl border border-border bg-gradient-to-br from-muted to-muted/50 transition-all duration-300 hover:border-primary/50 md:h-[500px] xl:h-[400px]">
                  <Image
                    src={app.previewScreens[0]?.screenUrl ?? ''}
                    alt={`${app.appName} screenshot`}
                    fill
                    draggable="false"
                    className="select-none object-cover transition-all duration-300 group-hover:scale-[1.02] md:object-contain"
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    quality={100}
                    priority
                  />
                </div>
                <div className="flex items-center justify-between px-2">
                  <div className="flex items-center gap-4">
                    <div className="relative h-10 w-10 overflow-hidden rounded-xl border border-border bg-white shadow-sm">
                      <Image
                        src={app.appLogoUrl}
                        alt={`${app.appName} logo`}
                        fill
                        draggable="false"
                        className="select-none object-contain p-1.5"
                        sizes="40px"
                      />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium leading-tight">
                        {app.appName}
                      </h3>
                      <p className="mt-0.5 line-clamp-1 text-sm text-muted-foreground">
                        {app.appTagline}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }

          // For other tabs, render WebScreen data
          const screen = item as WebScreen;
          const isSelected = selectedScreens.some(
            s => s.screenUrl === screen.screenUrl,
          );
          return (
            <div
              key={screen.appName}
              className="group flex cursor-pointer flex-col gap-4"
              onClick={() => {
                if (activeTab === 'apps') {
                  onAppClick(screen.appName);
                }
              }}
            >
              <div className="relative aspect-[4/3] w-full overflow-hidden rounded-2xl border border-border bg-gradient-to-br from-muted to-muted/50 transition-all duration-300 hover:border-primary/50 md:h-[500px] xl:h-[400px]">
                <Image
                  src={screen.screenUrl}
                  alt={`${screen.appName} screenshot`}
                  fill
                  draggable="false"
                  className="select-none object-cover transition-all duration-300 group-hover:scale-[1.02] md:object-contain"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  quality={100}
                  priority
                />
              </div>
              <div className="flex items-center justify-between px-2">
                <div className="flex items-center gap-4">
                  <div className="relative h-10 w-10 overflow-hidden rounded-xl border border-border bg-white shadow-sm">
                    <Image
                      src={screen.appLogoUrl}
                      alt={`${screen.appName} logo`}
                      fill
                      draggable="false"
                      className="select-none object-contain p-1.5"
                      sizes="40px"
                    />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium leading-tight">
                      {screen.appName}
                    </h3>
                    <p className="mt-0.5 line-clamp-1 text-sm text-muted-foreground">
                      {screen.appTagline}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {hasMore && !isFetching && filteredScreens.length > 0 && (
        <div className="flex justify-center pt-8">
          <button
            onClick={() => {
              setCurrentPage(prev => prev + 1);
              fetchScreensForCategory(selectedCategory, currentPage + 1);
            }}
            className="rounded-full bg-primary px-6 py-3 text-primary-foreground transition-colors duration-200 hover:bg-primary/90"
          >
            Load More
          </button>
        </div>
      )}

      {isFetching && currentPage > 1 && (
        <div className="flex justify-center pt-8">
          <div className="loading-spinner">Loading...</div>
        </div>
      )}
    </div>
  );
};

const ScreensContent = (props: TabContentProps) => (
  <SharedContent {...props} activeTab="screens" onAppClick={() => {}} />
);
const AppsContent = (props: TabContentProps) => (
  <SharedContent {...props} activeTab="apps" onAppClick={() => {}} />
);
const MarketingContent = (props: TabContentProps) => (
  <SharedContent {...props} activeTab="marketing" onAppClick={() => {}} />
);
const UIElementsContent = (props: TabContentProps) => (
  <SharedContent {...props} activeTab="ui" onAppClick={() => {}} />
);

interface AppDetailContentProps {
  appName: string;
}

const AppDetailContent = ({ appName }: AppDetailContentProps) => {
  const {
    allScreens,
    setAllScreens,
    isFetching,
    setIsFetching,
    selectedScreens,
    toggleScreenSelection,
  } = useInspirationStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [appDetails, setAppDetails] = useState<WebScreen | null>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  // Add scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        setShowScrollTop(contentRef.current.scrollTop > 500);
      }
    };

    const currentRef = contentRef.current;
    currentRef?.addEventListener('scroll', handleScroll);
    return () => currentRef?.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fetchScreensForApp = async (page: number = 1) => {
    setIsFetching(true);
    try {
      const { data } = await api.post('/api/web-screens/getScreensByAppName', {
        appName,
        page,
        limit: 20,
      });

      if (data && data.data) {
        setHasMore(data.data.length === 20);
        setAllScreens(page === 1 ? data.data : [...allScreens, ...data.data]);

        // Set app details from the first screen
        if (page === 1 && data.data.length > 0) {
          setAppDetails(data.data[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching app screens:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchScreensForApp(1);
  }, [appName]);

  return (
    <div ref={contentRef} className="relative h-full overflow-y-auto">
      {appDetails && (
        <div className="sticky top-0 z-10 border-b bg-background/80 pb-6 backdrop-blur-xl">
          <div className="flex items-start gap-8">
            <div className="relative h-20 w-20 flex-shrink-0 overflow-hidden rounded-2xl border border-border bg-white shadow-sm">
              <Image
                src={appDetails.appLogoUrl}
                alt={`${appDetails.appName} logo`}
                fill
                draggable="false"
                className="select-none object-contain p-2.5"
                sizes="80px"
              />
            </div>
            <div className="min-w-0 flex-1">
              <h2 className="truncate text-3xl font-bold">
                {appDetails.appName}
              </h2>
              <p className="mt-2 line-clamp-2 text-lg text-muted-foreground">
                {appDetails.appTagline}
              </p>
              {appDetails.appCategory && (
                <div className="mt-3">
                  <span className="rounded-full bg-primary/10 px-3 py-1 text-sm text-primary">
                    {appDetails.appCategory}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="space-y-8 pt-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 xl:grid-cols-3">
          {isFetching && currentPage === 1 && (
            <div className="col-span-full flex justify-center">
              <div className="loading-spinner">Loading...</div>
            </div>
          )}

          {!isFetching && allScreens.length === 0 && (
            <div className="col-span-full py-12 text-center">
              <h3 className="text-xl font-medium text-muted-foreground">
                No screens found for this app
              </h3>
            </div>
          )}

          {allScreens.map((screen, index) => {
            const isSelected = selectedScreens.some(
              s => s.screenUrl === screen.screenUrl,
            );
            return (
              <div
                key={`${screen.appName}-${index}`}
                className={`group flex cursor-pointer select-none flex-col gap-4 ${isSelected ? 'rounded-2xl ring-2 ring-orange-500' : ''}`}
                onClick={() => {
                  if (!isSelected && selectedScreens.length >= 5) {
                    toast.error('Maximum 5 screens allowed', {
                      description:
                        "You can unselect screens using the 'Preview selections' option at the bottom.",
                      duration: 4000,
                    });
                    return;
                  }
                  toggleScreenSelection(screen);
                }}
              >
                <div className="relative aspect-[4/3] w-full overflow-hidden rounded-2xl border border-border bg-gradient-to-br from-muted to-muted/50 transition-all duration-300 hover:border-orange-500/50 md:h-[500px] xl:h-[400px]">
                  {isSelected && (
                    <div className="absolute right-4 top-4 z-10 rounded-full bg-orange-500 p-2 text-white">
                      <Check className="h-4 w-4" />
                    </div>
                  )}
                  <Image
                    src={screen.screenUrl}
                    alt={`${screen.appName} screenshot`}
                    fill
                    draggable="false"
                    className="select-none object-cover transition-all duration-300 group-hover:scale-[1.02] md:object-contain"
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    quality={100}
                    priority={index < 6}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {hasMore && !isFetching && allScreens.length > 0 && (
          <div className="flex justify-center pt-8">
            <button
              onClick={() => {
                setCurrentPage(prev => prev + 1);
                fetchScreensForApp(currentPage + 1);
              }}
              className="rounded-full bg-primary px-6 py-3 text-primary-foreground transition-colors duration-200 hover:bg-primary/90"
            >
              Load More
            </button>
          </div>
        )}

        {isFetching && currentPage > 1 && (
          <div className="flex justify-center pt-8">
            <div className="loading-spinner">Loading...</div>
          </div>
        )}
      </div>

      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 z-50 rounded-full bg-primary p-3 text-primary-foreground shadow-lg transition-all duration-200 hover:bg-primary/90"
          aria-label="Scroll to top"
        >
          <ChevronUp className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export function ScreensModal({ isOpen, onClose, onInsert }: ScreensModalProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('apps');
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const filtersRef = useRef<HTMLDivElement>(null);
  const {
    setAllScreens,
    selectedScreens,
    clearSelectedScreens,
    toggleScreenSelection,
  } = useInspirationStore();
  const [showPreview, setShowPreview] = useState(false);
  const { addAttachments } = useAttachmentsStore();

  // Reset selected filter when changing tabs
  useEffect(() => {
    setSelectedFilter('All');
  }, [activeTab]);

  const scroll = (direction: 'left' | 'right') => {
    if (filtersRef.current) {
      const scrollAmount = 200;
      const newScrollLeft =
        direction === 'left'
          ? filtersRef.current.scrollLeft - scrollAmount
          : filtersRef.current.scrollLeft + scrollAmount;

      filtersRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const checkScroll = () => {
    if (filtersRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = filtersRef.current;
      setShowLeftShadow(scrollLeft > 0);
      setShowRightShadow(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const currentTabConfig = tabConfig[activeTab as keyof typeof tabConfig];

  const handleAppClick = (appName: string) => {
    setSelectedApp(appName);
  };

  const handleBack = () => {
    setSelectedApp(null);
    setAllScreens([]);
  };

  const handleInsertScreens = () => {
    if (onInsert) {
      onInsert(selectedScreens);
    } else {
      const screenAttachments = selectedScreens.map(screen => ({
        url: screen.screenUrl,
        name: `${screen.appName}-screenshot.png`,
        contentType: 'image/png',
      }));
      addAttachments(screenAttachments);
    }

    onClose();
    clearSelectedScreens();
  };

  const renderContent = () => {
    const props = { searchQuery, selectedCategory: selectedFilter };

    switch (activeTab) {
      case 'screens':
        return <ScreensContent {...props} />;
      case 'apps':
        return <AppsContent {...props} />;
      case 'marketing':
        return <MarketingContent {...props} />;
      case 'ui':
        return <UIElementsContent {...props} />;
      default:
        return <ScreensContent {...props} />;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="m-0 flex h-screen w-screen max-w-none flex-col rounded-none bg-gradient-to-b from-background to-muted/20 p-0">
          <div className="sticky top-0 z-50 border-b bg-background/80 px-6 py-8 backdrop-blur-xl md:px-12">
            <div className="mx-auto w-full max-w-[2400px] space-y-6">
              <div className="flex items-start justify-between">
                <div className="space-y-2.5">
                  <DialogTitle className="text-3xl font-bold tracking-tight md:text-4xl">
                    {selectedApp ? (
                      <button
                        onClick={handleBack}
                        className="flex items-center gap-2 rounded-lg px-2 py-1.5 text-base font-medium text-muted-foreground transition-all duration-200 hover:bg-muted hover:text-foreground"
                      >
                        <ChevronLeft className="h-5 w-5" />
                        <span>Back</span>
                      </button>
                    ) : (
                      'Inspiration Library'
                    )}
                  </DialogTitle>
                  {!selectedApp && (
                    <p className="max-w-2xl text-base leading-relaxed text-muted-foreground/80">
                      Browse and collect UI inspiration from popular apps.
                      Selected screens will be used as reference only - remember
                      to create your own unique designs rather than copying
                      directly.
                    </p>
                  )}
                </div>
                <button
                  onClick={onClose}
                  className="group rounded-full p-2.5 transition-colors duration-200 hover:bg-muted/80"
                >
                  <X className="h-5 w-5 text-muted-foreground transition-colors duration-200 group-hover:text-foreground" />
                </button>
              </div>

              {!selectedApp && (
                <>
                  <div className="flex items-center gap-4">
                    <div className="group relative max-w-2xl flex-1">
                      <div className="absolute left-4 top-1/2 -translate-y-1/2 transform text-muted-foreground/60 transition-colors duration-200 group-focus-within:text-primary">
                        <Search className="h-5 w-5" />
                      </div>
                      <Input
                        placeholder={currentTabConfig.searchPlaceholder}
                        className="border-border/40 h-12 w-full rounded-full bg-background pl-12 text-base shadow-sm transition-all duration-200 hover:border-border focus-visible:border-primary focus-visible:ring-1 focus-visible:ring-primary/30"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="relative -mb-2">
                    {showLeftShadow && (
                      <button
                        onClick={() => scroll('left')}
                        className="border-border/50 absolute -left-3 top-1/2 z-10 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full border bg-background/80 shadow-lg transition-colors duration-200 hover:bg-background"
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </button>
                    )}

                    <div
                      ref={filtersRef}
                      onScroll={checkScroll}
                      className="scrollbar-hide relative flex items-center gap-2 overflow-x-auto pb-2"
                    >
                      {currentTabConfig.filters.map(filter => (
                        <button
                          key={filter}
                          onClick={() => setSelectedFilter(filter)}
                          className={`whitespace-nowrap rounded-full px-4 py-2 text-sm transition-all duration-200 ${
                            selectedFilter === filter
                              ? 'bg-primary text-primary-foreground shadow-sm'
                              : 'bg-muted/50 text-muted-foreground hover:bg-muted hover:text-foreground'
                          }`}
                        >
                          {filter}
                        </button>
                      ))}
                    </div>

                    {showRightShadow && (
                      <button
                        onClick={() => scroll('right')}
                        className="border-border/50 absolute -right-3 top-1/2 z-10 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full border bg-background/80 shadow-lg transition-colors duration-200 hover:bg-background"
                      >
                        <ChevronRight className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            {!selectedApp ? (
              // Show original content with search and filters
              <>
                <div className="space-y-6 border-b bg-background/80 px-6 py-6 backdrop-blur-xl md:px-12">
                  <div className="mx-auto max-w-[2400px]">
                    <SharedContent
                      searchQuery={searchQuery}
                      selectedCategory={selectedFilter}
                      activeTab={activeTab}
                      onAppClick={handleAppClick}
                    />
                  </div>
                </div>
              </>
            ) : (
              // Show app-specific content
              <div className="px-6 py-8 md:px-12">
                <div className="mx-auto max-w-[2400px]">
                  <AppDetailContent appName={selectedApp} />
                </div>
              </div>
            )}
          </div>

          {/* Selection controls - with orange tints */}
          {activeTab === 'apps' && selectedScreens.length > 0 && (
            <div className="fixed bottom-0 left-0 right-0 border-t bg-background/95 shadow-lg backdrop-blur-xl">
              <div className="mx-auto flex w-full max-w-[2400px] items-center justify-between px-6 py-4 md:px-12">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2 rounded-full bg-orange-500/10 px-4 py-1.5 text-orange-500">
                    <div className="h-2 w-2 animate-pulse rounded-full bg-orange-500" />
                    <span className="text-sm font-medium">
                      {selectedScreens.length} of 5 screens selected
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={clearSelectedScreens}
                    className="text-muted-foreground hover:text-orange-600"
                  >
                    Clear all
                  </Button>
                </div>
                <div className="flex items-center gap-3">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setShowPreview(true)}
                    className="gap-2 hover:border-orange-600 hover:text-orange-600"
                  >
                    <Eye className="h-4 w-4" />
                    Preview selections
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleInsertScreens}
                    className="gap-2 border-none bg-orange-500 text-white hover:bg-orange-600"
                  >
                    <Plus className="h-4 w-4" />
                    Insert {selectedScreens.length}{' '}
                    {selectedScreens.length === 1 ? 'screen' : 'screens'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* Preview Dialog - with unselect functionality */}
      <Dialog open={showPreview} onOpenChange={setShowPreview}>
        <DialogContent className="flex max-h-[90vh] w-[95vw] max-w-6xl flex-col overflow-hidden">
          <DialogHeader>
            <DialogTitle className="text-orange-600">
              Selected Screens
            </DialogTitle>
          </DialogHeader>
          <div className="-mr-6 flex-1 overflow-y-auto pr-6">
            <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
              {selectedScreens.map(screen => (
                <div
                  key={screen.screenUrl}
                  className="group relative aspect-[4/3] cursor-pointer select-none overflow-hidden rounded-lg border border-orange-200 transition-colors duration-200 hover:border-orange-500"
                  onClick={() => {
                    toggleScreenSelection(screen);
                    toast.success('Screen removed from selection');
                  }}
                >
                  <div className="absolute right-4 top-4 z-10">
                    <div className="rounded-full bg-orange-500 p-2 text-white">
                      <X className="h-4 w-4" />
                    </div>
                  </div>
                  <Image
                    src={screen.screenUrl}
                    alt={`${screen.appName} screenshot`}
                    fill
                    draggable="false"
                    className="select-none object-cover"
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-orange-500/10 p-2 backdrop-blur-sm">
                    <p className="text-sm font-medium text-orange-700">
                      {screen.appName}
                    </p>
                  </div>
                  <div className="absolute inset-0 bg-orange-500/0 transition-colors duration-200 group-hover:bg-orange-500/5" />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
