import React, { useState, useEffect } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Copy } from 'lucide-react';
import { toast } from 'sonner';
import { useWebappStore } from '@/store/useWebappData';
import api from '@/lib/axios';
import CodeDisplay from '@/components/Canvas/components/common/CodeDisplay';
import { CodeBlock } from '@/components/ui/code-block';

export interface CodeFile {
  path: string;
  oldContent: string;
  newContent: string;
  status: 'added' | 'modified' | 'deleted';
}

export function CodeViewer() {
  const { data } = useWebappStore();
  const [files, setFiles] = useState<CodeFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCommitData = async () => {
    if (!data?.currentCommitHash || !data?.subdomain) {
      console.log('Missing required data', {
        currentCommitHash: data?.currentCommitHash,
        subdomain: data?.subdomain,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post('/git/commits/diff', {
        currentCommit: data.currentCommitHash,
        subdomain: data.subdomain,
        isSourceCode: true,
      });

      setFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching commit data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.currentCommitHash) {
      fetchCommitData();
    }
  }, [data?.currentCommitHash]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">Loading...</div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <ScrollArea className="flex-1 p-4">
        <div className="grid grid-cols-1 space-y-6">
          {files.map((file, index) => (
            <CodeBlock
              key={index}
              code={file.newContent}
              filename={file.path}
              language={getLanguageFromPath(file.path)}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}

export function getLanguageFromPath(path: string): string {
  const extension = path.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'ts':
    case 'tsx':
      return 'typescript';
    case 'js':
    case 'jsx':
      return 'javascript';
    case 'css':
      return 'css';
    case 'html':
      return 'html';
    case 'json':
      return 'json';
    default:
      return 'typescript';
  }
}
